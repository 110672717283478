.navbar-main {
  z-index: 1000;
  top: 0;
  width: 100%;
  position: fixed;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #000000;
  height: 80px;
  padding: 0 25px;
}

.placeholder {
  width: 30px;
}

.navbar-open .category-selector {
  display: none;
}

.logo img {
  width: 150px;
}

.burger {
  cursor: pointer;
  display: flex;
  flex-direction: column;
}

.line {
  width: 30px;
  height: 3px;
  background-color: rgb(231, 230, 228);
  margin: 4px;
  transition: all 0.3s ease;
}

.nav-links {
  display: none;
  flex-direction: column;
  background-color: #000000;
  width: 100%;
  height: 100%;
  list-style: none;
  height: 90vh;
  margin-top: 0px;
  padding-top: 50px;
  position: relative;
  z-index: 1000;
}

.nav-links.open {
  display: flex;
}


.nav-links li {
  padding: 10px;
  text-align: center;
  font-size: 25px;
}

.nav-links li a {
  color: rgb(243, 241, 232);
  text-decoration: none;

}

.burger .line.open {
  transform: rotate(45deg) translate(10px, 5px);
}

.burger .line.open:nth-child(2) {
  opacity: 0;
}

.burger .line.open:nth-child(3) {
  transform: rotate(-45deg) translate(10px, -5px);
}