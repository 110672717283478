.cookie-banner {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #000000e2;
    color: #fff;
    padding: 10px;
    display: flex;
    flex-direction: column;
    z-index: 1000;
    font-size: 13px;
  }
  
  .cookie-banner p {
    margin: 0 0 10px;
  }
  
  .cookie-banner a {
    color: #FFD700;
    text-decoration: underline;
  }
  
  .cookie-preferences {
    margin: 5px 0;
  }
  
  .cookie-preferences label {
    display: flex;
    align-items: center;
    gap: 10px; /* Abstand zwischen Checkbox und Text */
    margin-bottom: 10px; /* Abstand zwischen den einzelnen Labels */
  }
  
  .cookie-preferences input[type="checkbox"] {
    width: 18px; /* Breite der Checkbox */
    height: 18px; /* Höhe der Checkbox */
    cursor: pointer; /* Zeigt den Mauszeiger als "Hand" bei Hover */
  }
  
  .cookie-preferences h4 {
    margin-bottom: 15px;
    font-size: 16px;
    font-weight: bold;
  }
  
  
  .cookie-buttons {
    display: flex;
    gap: 10px;
    margin-top: 10px;
  }
  
  .accept-button,
  .decline-button,
  .save-button {
    padding: 7px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
  }
  
  .accept-button {
    background-color: #000000e8;
    color: #FFD700;
  }
  
  .decline-button {
    background-color: #000000e8;
    color: #FFD700;
  }
  
  .save-button {
    background-color: #FFD700;
    color: #fff;
  }

  /* --- Media Query for Mobile Devices --- */
@media (max-width: 600px) {
  .cookie-banner {
    font-size: 12px; /* Reduzierte Schriftgröße */
    padding: 8px; /* Weniger Padding */
  }

  .cookie-buttons {
    flex-direction: column; /* Buttons untereinander statt nebeneinander */
    gap: 8px; /* Abstand zwischen Buttons */
  }

  .accept-button,
  .decline-button,
  .save-button {
    font-size: 13px; /* Leicht kleinere Buttons */
    padding: 6px 8px; /* Weniger Padding */
    width: 100%; /* Buttons nehmen die gesamte Breite ein */
  }
}