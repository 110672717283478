/* Haupt-Container für die Bestellübersicht */
.orderOverviewContainer {
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 1250px;
  /* Größere Maximalbreite für mehr Übersichtlichkeit */
  margin-left: 250px;
  /* Zentriert den Container und fügt oberen und unteren Abstand hinzu */
  padding: 40px;
  background-color: #ffffff;
}

@media (max-width: 768px) {
  .orderOverviewContainer {
    margin-left: 0px;
  }
}

/* Tabellen-Styling */
.infoTable {
  width: 500px;
  border-collapse: collapse;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .infoTable {
    width: 100%;
  }
}

.infoTable th,
.infoTable td {
  padding: 10px;
  border: 1px solid #ddd;
  text-transform: capitalize;
}

.infoTable th {
  background-color: #adb1ad;
  color: white;
  text-align: left;
  font-weight: 600;
  text-transform: capitalize;

}

.infoTable tr:nth-child(even) {
  background-color: #f9f9f9;
  /* Helleres Grau für bessere Sichtbarkeit */
}

.infoTable td {
  font-size: 1rem;
  /* Einheitliche Schriftgröße */
  color: #555;
  /* Dunklere Schriftfarbe für besseren Kontrast */
}

/* Filter-Container */
.dateFilter {
  display: flex;
  align-items: center;
  gap: 15px;
  /* Etwas mehr Abstand zwischen den Filtern */
  margin-bottom: 20px;
  justify-content: center;
}

.dateFilter select {
  margin-right: 10px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.dateFilter input {
  margin-right: 10px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Schaltflächen-Styling */
.okButton {
  padding: 8px 16px;
  background-color: #a7a9a8;
  color: white;
  border: none;
}

.okButton:hover {
  background-color: #adb1ad;
  transition: background-color 0.3s ease;
}

.okButton:hover {
  background-color: #414141;
}

.ordersTableCont {
  width: 100%;
  overflow-x: scroll;
}

.ordersTable {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  overflow-x: scroll;
}

.ordersTable th {
  background-color: #adb1ad;
  color: white;
  padding: 10px;
  text-align: left;
  text-transform: capitalize;

}

.ordersTable td {
  padding: 10px;
  border: 1px solid #ddd;
  min-width: max-content;

}

.ordersTablediv {
  max-width: 250px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ordersTable tr:nth-child(even) {
  background-color: #f2f2f2;
}

.ordersTable tr:hover {
  white-space: normal; /* Zeigt den kompletten Text bei Hover */
  overflow: visible;
  background-color: #f8f8f8;
  padding: 5px;
  border-radius: 5px;
}

.ordersTable div {
  margin-bottom: 10px;
}

.ordersTable strong {
  color: #333;
}