.schedule-container {
    font-family: Arial, sans-serif;
    margin: 25px;
    margin-top: 120px;
    margin-bottom: 120px;
  }
  
  .schedule-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .schedule-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .schedule-table th,
  .schedule-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .schedule-table th {
    background-color: #f4f4f4;
    font-weight: bold;
  }
  
  .schedule-table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .schedule-table tr:hover {
    background-color: #f1f1f1;
  }
  
  .schedule-table td {
    font-size: 14px;
  }
  