:root {
  /* Definieren Sie Farben und häufig verwendete Werte hier */
  --color-text-dark: #333;
  --color-text-light: #777;
  --color-bg-light: #f9f9f9;
}


.checkout-container {
  display: flex;
  justify-content: space-between;
  padding: 40px;
  gap: 20px;
  margin: 0 25px;
}

.content-container {
  width: 60%;
}

@media screen and (max-width: 1000px) {

  .checkout-container {
    padding: 13px;
  }

}

/* Container */
.dropdown-container {
  position: relative;
  width: 250px;
  font-family: Arial, sans-serif;
}

/* Trigger Box */
.dropdown-trigger {
  background: #f3f3f3;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdown-trigger.open {
  border-color: #007bff;
}

/* Arrow */
.dropdown-arrow {
  margin-left: 10px;
  font-size: 12px;
}


@media screen and (max-width: 1000px) {

  .content-container {
    width: 100%;
  }

}

.form-container {
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin-top: 90px;
}

.form-group label {
  display: flex;
  flex-direction: column;
  order: 1;
  font-weight: 500;
  margin-bottom: 5px;
  font-size: 0.9rem;
  color: var(--color-text-dark);
}

.form-group input {
  order: 2;
  padding: 12px;
  border: none;
  font-size: 0.9rem;
  color: var(--color-text-dark);
  background-color: var(--color-bg-light);
  /* width: 350px; */
}

.input-group-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* Change as needed */
  gap: 5px;
  /* Creates a 5px gap between grid elements */
}

@media screen and (max-width: 768px) {

  .input-group-row {
    display: grid;
    grid-template-columns: 1fr;
    /* Change as needed */
  }
  .button {
    width: auto; /* Button wird auf mobilen Geräten die verfügbare Breite einnehmen */
    max-width: 100%; /* Stellt sicher, dass der Button die volle Breite des Containers einnimmt */

  }
  
}

.input-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.checkout-title {
  font-weight: bold;
  color: #ffcc00;
  /* This is a shade of gray */
  font-size: 20px;
  margin-top: 10px;
  text-shadow:
    -3px -3px 0 #000,
    3px -3px 0 #000,
    -3px 3px 0 #000,
    3px 3px 0 #000,
    -3px 0px 0 #000,
    3px 0px 0 #000,
    0px -3px 0 #000,
    0px 3px 0 #000;
}

/* Nachricht bei gültigem oder ungültigem Code */
.status-message {
  font-size: 0.85rem;
  /* Kleinere Schriftgröße */
  opacity: 0;
  /* Unsichtbar, bis animiert */
  transform: translateY(10px);
  /* Startet leicht nach unten versetzt */
  animation: fadeInUp 0.5s ease-out forwards;
}

/* Grüner Text für gültigen Code */
.status-message.valid {
  color: green;
}

/* Roter Text für ungültigen Code */
.status-message.invalid {
  color: red;
}

/* Keyframes für sanftes Einblenden und Hochbewegen */
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.time-selection {
  margin-bottom: 20px;
}

.time-selection select {
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
}

.checkbox-group {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.checkbox-group label {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 0.9rem;
  color: var(--color-text-light);
}



.checkbox-group input[type="checkbox"] {
  margin-right: 10px;
}

.paypal-buttons-container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.button-container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.button {
  padding: 15px 15px;
  width: 750px;
  
  background-color: #676a6d;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1.1rem;
  cursor: pointer;
  margin-bottom: 15px;
  transition: background-color 0.3s;
}

.button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.button:hover:not(:disabled) {
  background-color: #1f1f20;
}

.dropdown-menu-chec {
  position: absolute;
  top: 65%;
  left: 0;
  width: 100%;
  max-height: 160px;
  overflow-y: auto;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  z-index: 1000;
}

.styled-link {
  color: #ffcc00; /* Stilisiere die Link-Farbe */
  text-decoration: none; /* Hebe den Link hervor */
  cursor: pointer;
}

.styled-link:hover {
  color: #fdbb04; /* Ändere die Farbe bei Hover */
  text-decoration: none; /* Entferne die Unterstreichung bei Hover */
}