.directions-container {
    font-family: Arial, sans-serif;
    margin: 20px;
    margin-top: 80px;
    padding: 20px;

  }
  
  .directions-title {
    font-size: 30px;
    font-weight: bold;
    text-transform: uppercase;
    color: #333;
    margin-bottom: 15px;
  }
  
  .directions-content h2 {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 5px;
    color: #444;
  }
  
  .directions-content p {
    margin: 5px 0;
    color: #666;
  }
  
  .google-maps-link {
    display: inline-block;
    margin: 10px 0;
    text-decoration: none;
    color: #007bff;
    font-weight: bold;
    font-size: 14px;
  }
  
  .google-maps-link:hover {
    text-decoration: underline;
  }
  
  .contact-info h3 {
    font-size: 16px;
    font-weight: bold;
    margin-top: 15px;
    margin-bottom: 5px;
  }
  
  .contact-info p {
    color: #666;
    margin: 0;
  }
  