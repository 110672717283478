/* Modernes Layout für Kategorie-Auswahl */
.category-selector {
  display: none;
  /* Zunächst unsichtbar */
  flex-direction: row;
  /* Flexbox aktivieren, um die Schaltflächen gleichmäßig zu verteilen */
  justify-content: left;
  align-items: center;
  /* Zentriert die Schaltflächen vertikal */
  background-color: #ffffff;
  /* Heller, sauberer Hintergrund */
  padding: 5px 25px;
  /* Mehr Platz um die Schaltflächen */
  top: 80px;
  /* Abstand zum oberen Bildschirmrand */
  z-index: 999;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* Deutlicher Schatten für Tiefeneffekt */
  overflow-x: scroll;scrollbar-width: none; /* Firefox */
}

.category-selector::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Edge */
}

.category-selector.sticky {
  display: flex;
  position: sticky;
  /* Sticky-Position */
}

/* Optimierte Kategorie-Schaltflächen */
.category-selector button {
  background-color: #ffffffc7;
  /* Hintergrundfarbe der Schaltflächen */
  border: 1px;
  border-radius: 16px;
  /* Abgerundete Ecken */
  padding: 6px 20px;
  /* Padding für Schaltflächen */
  font-size: 1.0rem;
  /* Schriftgröße */
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease, transform 0.2s ease;
  /* Sanfter Übergang */
  color: #333;
  /* Textfarbe */
  margin: 0 10px;
  /* Abstand zwischen den Schaltflächen */
  text-transform: capitalize;
  width: max-content;
  min-width: max-content;

}

/* Hover-Effekt für Schaltflächen */
.category-selector button:hover {
  background-color: #e0e0e0;
  /* Hellerer Hintergrund beim Hover */
  color: #333;
  /* Textfarbe beim Hover */
  border-color: #ffcc00;
  /* Border-Farbe beim Hover */
  transform: scale(1.05);
  /* Vergrößerungseffekt beim Hover */
}

/* Aktive Kategorie */
.category-selector button.active {
  background-color: #ffcc00;
  /* Hintergrundfarbe für aktive Schaltfläche */
  color: #000000;
  /* Textfarbe für aktive Schaltfläche */
}

/* Abstand für sticky Kategorie-Auswahl */
.order-content {
  margin-top: 40px;
  /* Zusätzlicher Platz oben, damit sticky besser wirkt */
}