/* Produktliste Gesamt-Layout */
.product-main {
    display: flex;
    flex-direction: column;
    width: 60%;
}

@media screen and (max-width: 1000px) {
    .product-main {
        width: 100%;
    }
}

.product-content {
    padding: 10px;
    /* Innenabstand der Produktkarte */
   
    /* Rahmen für die Karte */
    margin-bottom: 15px;
    /* Abstand zwischen den Produktkarten */
    background-color: #ffffffec;
    /* Weißer Hintergrund für Klarheit */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* Leichter Schatten für Tiefe */
    border-radius: 4px;
}

.product-title {
    padding-left: 10px;
    font-size: 1.2rem;
    /* Kleinere Schriftgröße für Titel */
    margin: 0;
    /* Kein Margin */
    line-height: 1.2;
    /* Zeilenhöhe */
    padding-top: 3px;

}

.product-infos-container {
    display: flex;
    /* Flexbox aktivieren */
    justify-content: space-between;
    /* Verteile den Platz zwischen den Elementen */
    align-items: center;
    /* Vertikal zentrieren */
}

.product-info-button {
    padding: 3px 6px;
    /* Optional: Padding für den Button */
    margin-right: auto;
    margin-left: 5px;
    color: #333333;
    /* Weißer Text */
    border: none;
    /* Keine Rahmen */
    cursor: pointer;
    /* Zeigt einen Zeiger-Cursor an */
    border-radius: 40px;
}

.product-info-button:hover {
    background-color: #dedede;
    /* Ändere die Farbe beim Hover */
}


.product-description {
    font-size: 0.9rem;
    /* Kleinere Schriftgröße für Beschreibung */
    margin: 4px 0;
    /* Weniger Abstand zwischen Beschreibung und Preis */
    line-height: 1.2;
    /* Zeilenhöhe */
    padding-left: 10px;
    /* Text nach rechts verschieben */
    margin-bottom: 0;
    color: #333;
    /* Hellere Schwarz */
    font-style: italic;
    /* Kursivschrift */
}


.product-price {
    font-size: 1.2rem;
    /* Preis Schriftgröße */
    font-weight: bold;
    /* Fett für den Preis */
    color: #000000;
    /* Schwarze Schriftfarbe für den Preis */
    padding-left: 10px;
    /* Text nach rechts verschieben */
    margin: 8px 0;
    animation: glow 1s ease-in-out infinite alternate;
}

@keyframes glow {
    from {
        text-shadow: 0 0 10px #ffc107, 0 0 20px #ffc107, 0 0 30px #ffc107;
    }

    to {
        text-shadow: 0 0 20px #ffcd38, 0 0 30px #ffcd38, 0 0 40px #ffcd38;
    }
}




/* Kategorie-Titel */
.category-title {
    font-size: 32px;
    /* Größere und fettere Schrift */
    font-weight: bold;
    border-radius: 4px;
    margin-bottom: 30px;
    /* Mehr Abstand unter und zwischen Kategorien */
    margin-top: 20px;
    padding: 20px;
   
    color: #ffc107;
    /* Gelbe Schriftfarbe */
    font-weight: bold;
    /* Dicke Schrift */
    text-shadow:
        -3px -3px 0 #000,
        3px -3px 0 #000,
        -3px 3px 0 #000,
        3px 3px 0 #000,
        -3px 0px 0 #000,
        3px 0px 0 #000,
        0px -3px 0 #000,
        0px 3px 0 #000;

}