/* Overlay für das Popup */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 30px 30px;
  background-color: rgba(0, 0, 0, 0.7);
  display: grid;
  place-items: center;
  z-index: 1000;
  overflow-y: auto;
}

@media screen and (max-width: 600px) {
  .popup-overlay {
    padding: 13px 13px;
  }
}

/* Popup-Stil */
.popup {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 20px;
  width: 90%;
  max-width: 600px;
  /* Feste Höhe für das Popup */
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  position: relative;
  display: flex;
  flex-direction: column;

}

/* Schließen-Button im Popup */
.close-button {
  position: absolute;
  top: 35px;
  right: 25px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

/* Fester Bereich für die Anzahl und Buttons */
.fixed-buttons {
  position: absolute;
  bottom: 20px;
  /* Abstand vom unteren Rand */
  left: 20px;
  /* Abstand vom linken Rand für Count-Buttons */
  right: 20px;
  /* Abstand vom rechten Rand für Add-to-Cart-Button */
  display: flex;
  justify-content: space-between;
  /* Buttons an den äußeren Rändern platzieren */
  align-items: center;
  background-color: white;
}

/* Container für die Count-Buttons */
.count-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Count-Buttons */
.count-button {
  background-color: #1f1f1f;
  color: #ffc107;
  border: none;
  border-radius: 4px;
  padding: 10px 15px;
  /* Größe */
  font-size: 1.3rem;
  /* Schriftgröße */
  cursor: pointer;
  margin: 0 5px;
  /* Abstände */
}

.count-button:hover {
  background-color: #2c2c2b;
}

/* Count-Anzeige */
.count-display {
  font-size: 25px;
  /* Schriftgröße */
  margin: 0 10px;
  /* Abstände */
}

/* Add-to-Cart Button */
.add-to-cart-button {
  background-color: #ffc107;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 15px;
  /* Größe */
  font-size: 20px;
  /* Schriftgröße */
  cursor: pointer;
  width: 400px;
  transition: background-color 0.3s;
}

.add-to-cart-button:hover {
  background-color: #ffc107cf;
}

/* Button deaktiviert (rot) */
.add-to-cart-button.disabled {
  background-color: #dc3545;
  /* Rot */
  cursor: not-allowed;
}

/* Fehlertext für die Auswahl des Dressings 
.error-text {
  color: #dc3545; 
  font-size: 0.9rem;
  margin-top: 5px;
}*/

/* Scrollbarer Bereich für den restlichen Inhalt */
.scrollable-content {
  /* Scrollen aktivieren */
  flex-grow: 1;
  /* Restlichen Platz einnehmen */
  padding-bottom: 100px;
  /* Platz für fixe Elemente unten */
}

.scrollable-content::-webkit-scrollbar {
  width: 6px;
}

.scrollable-content::-webkit-scrollbar-thumb {
  background-color: #bbb;
  border-radius: 4px;
}

.scrollable-content::-webkit-scrollbar-track {
  background-color: #f0f0f0;
}

/* Container für die Popup-Titel */
.popup-title {
  margin-top: 10px;
  /* Abstand nach oben */
  margin-bottom: 15px;
  /* Abstand nach unten */
  padding: 10px;
  background-color: #ffcc00;
  /* Leichter Hintergrund */
  border-radius: 8px;
  /* Runde Ecken */
  text-align: left;
  /* Zentrierter Text */
}

/* h2 spezifisches Styling */
.popup-title h2 {
  color: #161515;
  /* Dunkle Schriftfarbe */
  font-size: 1.5rem;
  /* Größere Schriftgröße */
  margin-top: 0;
  /* Kein Abstand oben */
  margin-bottom: 10px;
  /* Abstand unten */
  font-weight: bold;
  /* Fettschrift */
}

/* p spezifisches Styling */
.popup-title p {
  color: #5c5c5c;
  /* Hellere Schriftfarbe */
  font-size: 1rem;
  /* Standard Schriftgröße */
  margin: 0;
  /* Kein Abstand */
  font-style: italic;
  /* Kursivschrift */

}

/* Container für Varianten */
.variants-container {
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

/* Überschrift für Varianten */
.variants-container h3 {
  color: #5c5c5c;
  /* Hellere Schriftfarbe */
  font-size: 1.2rem;
  margin-bottom: 5px;
  font-style: italic;
}

/* Label für Varianten */
.variants-container label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
  font-size: 0.9rem;
  color: #333;
  /* Dunklere Schriftfarbe */
  cursor: pointer;
  padding: 5px 10px;
  border: 2px solid transparent;
  transition: border-color 0.3s, background-color 0.3s;
}

/* Radio-Buttons */
.variants-container input[type="radio"] {
  appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #ddd;
  border-radius: 4px;
  /* Viereckige Form */
  background-color: #fff;
  margin-right: 10px;
  position: relative;
  cursor: pointer;
}

/* Checkmark für ausgewählte Radio-Buttons */
.variants-container input[type="radio"]:checked::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 12px;
  height: 12px;
  background-color: #ffc107;
  /* Gelbe Farbe für den Check */
}

/* Preis-Stil */
.variant-price {
  margin-left: auto;
  /* Preis nach rechts verschieben */
  font-size: 0.9rem;
  /* Kleinere Schriftgröße */
  color: #bbb;
  /* Hellere Farbe für den Preis */
  font-weight: normal;
  /* Dünnere Schrift */
}


/* Container für Extras */
.extra-container,
.dips-container {
  padding: 15px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

/* Überschrift für Extras */
.extra-container h3,
.dips-container h3 {
  color: #5c5c5c;
  /* Hellere Schriftfarbe */
  font-size: 1.2rem;
  margin-bottom: 10px;
  font-style: italic;
}

/* Extra-Items */
.extra-item,
.dips-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
  /* Kleinere Abstände zwischen den Extras */
  padding: 5px 10px;
  border: 2px solid transparent;
  transition: border-color 0.3s, background-color 0.3s;
}

/* Label für Extra-Items */
.extra-item label,
.dips-item label {
  display: flex;
  align-items:
    center;
  width: 100%;
}

/* Extra-Namen */
.extra-name,
.dips-name {
  margin-left: 10px;
  font-size: 0.9rem;
}

/* Extra-Preise */
.extra-price,
.dips-price {
  margin-left: auto;
  /* Preis nach rechts verschieben */
  font-size: 0.9rem;
  /* Kleinere Schriftgröße */
  color: #bbb;
  /* Hellere Farbe für den Preis */
  font-weight: normal;
  /* Dünnere Schrift */
}

/* Checkbox-Stil */
input[type="checkbox"] {
  appearance: none;
  width: 25px;
  height: 25px;
  border: 2px solid #ddd;
  background-color: #fff;
  border-radius: 4px;
  position: relative;
  cursor: pointer;
}

input[type="checkbox"]:checked {
  background-color: #ffc107;
  border-color: #ffc107;
}

input[type="checkbox"]:checked::before {
  content: '✔';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 18px;
  animation: tick-anim 0.3s ease-in-out;
}

/* Dressing-Container */
.dressing-container {
  padding: 15px;
  margin-bottom: 20px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Überschrift für Dressings */
.dressing-container h3 {
  color: #5c5c5c;
  /* Hellere Schriftfarbe */
  font-size: 1.2rem;
  margin-bottom: 10px;
  font-style: italic;
}

/* Custom Select Box */
.custom-select {
  position: relative;
  display: inline-block;
  max-width: 96%;
  border: 2px solid transparent;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: border-color 0.3s, background-color 0.3s;
  margin-bottom: 20px;
  margin-right: 10px;
  /* Einheitlicher Abstand nach unten */
}

/* Ausgewählte Option */
.selected {
  padding: 4px;
  font-size: 1rem;
  color: #333;
}

/* Dropdown Optionen */
.options {
  position: absolute;
  top: 106%;
  left: 0;
  right: 0;
  border: 1px solid #ddd;
  border-radius: 0 0 8px 8px;
  background-color: #fff;
  max-height: 200px;
  /* Maximalhöhe für das Dropdown */
  overflow-y: auto;
  /* Scrollen, wenn zu viele Optionen */
  z-index: 10;
  /* Sicherstellen, dass das Dropdown über anderen Elementen angezeigt wird */
  display: none;
  /* Zuerst ausblenden */
}

.custom-select.open .options {
  display: block;
  /* Optionen beim Klick anzeigen */
}

.option {
  padding: 10px;
  font-size: 1rem;
  color: #333;
  cursor: pointer;
  transition: background-color 0.3s;
}

.option:hover {
  background-color: #f0f0f0;
  /* Hintergrundfarbe bei Hover */
}