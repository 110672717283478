.modal {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
    z-index: 1001;
  }
  
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 5px;
    width: 300px;
  }
  
  .modal-content h3{
    color: #333; /* This is a shade of gray */
    font-size: 24px;
    margin-top: 10px;
  }

  .modal-content p{
    color: #333; /* This is a shade of gray */
    font-size: 18px;
  }

  /* Styling für das Select-Element */
.custom-select {
  width: 100%; /* Passt sich der Breite des Containers an */
  padding: 10px; /* Innenabstand für mehr Platz */
  font-size: 1rem; /* Größe der Schrift */
  border-radius: 5px; /* Abgerundete Ecken */
  border: 1px solid #ccc; /* Rahmenfarbe */
  background-color: #fff; /* Hintergrundfarbe */
  color: #333; /* Textfarbe */
  appearance: none; /* Entfernt das Standard-Pfeilsymbol im Dropdown */
  cursor: pointer; /* Zeigt den Zeiger an */
  transition: border-color 0.2s ease-in-out;
}

/* Hover-Effekt */
.custom-select:hover {
  border-color: #ffcc00; /* Farbe des Rahmens bei Hover */
}

/* Fokus-Effekt */
.custom-select:focus {
  outline: none; /* Entfernt den Standardfokus */
  border-color: #ffcc00; /* Blaue Rahmenfarbe bei Fokus */
}

/* Styling für die Option */
.custom-select option {
  padding: 10px;
}


/* Container für die Schaltflächen */
.button-group {
  display: flex;
  justify-content: space-between; /* Am rechten Rand ausrichten */
  margin-top: 20px; /* Abstand zum vorherigen Inhalt */
}

/* Bestätigen-Button */
.confirm-button {
  background-color: #4caf50; /* Grüner Farbton */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  padding: 10px 30px;
}

.confirm-button:hover {
  background-color: #45a049; /* Dunklerer Grünton bei Hover */
}

/* Schließen-Button */
.close {
  background-color: #ff6666; /* Roter Farbton */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  padding: 10px 30px;
}

.close:hover {
  background-color: #c82333; /* Dunklerer Rotton bei Hover */
}


.custom-dropdown {
  position: relative;
  width: 100%;
  cursor: pointer;
}

.dropdown-header {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdown-menu {
  position: absolute;
  top: 106%;
  left: 0;
  width: 96%;
  max-height: 160px;
  overflow-y: auto;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  z-index: 1000;
}

.dropdown-item {
  padding: 10px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
}

.dropdown-item:last-child {
  border-bottom: none;
}

.dropdown-item:hover {
  background-color: #f0f0f0;
}
