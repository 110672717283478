/* Warenkorb-Stil */
.shoppingCart {
  width: 450px;
  /* Feste Breite für den Warenkorb */
  height: 70vh;
  /* Feste Höhe für den Warenkorb */
  /* Dezenter Rand */
  padding: 15px;
  border-radius: 4px;
  position: sticky;
  /* Fixed Position für den Warenkorb */
  /* Abstand von oben, weiter nach unten */
  background-color: #ffffffe9;
  color: #333;
  overflow: hidden;
  /* Verhindert das Scrollen des gesamten Warenkorbs */
  display: flex;
  /* Flexbox aktivieren */
  flex-direction: column;
  justify-content: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

@media screen and (min-width: 1000px) {
  .shoppingCart {
    top: 22%;
  }
}

.chevron {
  display: none;
}

/* Scrollbarer Bereich für die Produkte */
.scrollableCart {
  height: calc(80vh - 300px);
  /* Höhe minus der fixen Bereiche */
  overflow-y: auto;
  /* Scrollbar nur hier */
  flex: 1;
  padding: 0;
  /* Kein Padding mehr, um Platz zu sparen */
}

/* Scroll-Leiste für die Produkte */
.scrollableCart::-webkit-scrollbar {
  width: 6px;
}

.scrollableCart::-webkit-scrollbar-thumb {
  background-color: #bbb;
  border-radius: 4px;
}

.scrollableCart::-webkit-scrollbar-track {
  background-color: #f0f0f0;
}

.headerCart {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffcc00;
  border-radius: 40px;
  height: 45px;
}


@media screen and (max-width: 1000px) {
  .shoppingCart {
    width: 97%;
    /* Feste Breite für den Warenkorb */
    height: 85vh;
    /* Feste Höhe für den Warenkorb */
    border: none;
    /* Dezenter Rand */
    padding: 8px;
    border-radius: 4px;
    position: fixed;
    /* Fixed Position für den Warenkorb */
    /* top: 89%; */
    /* Abstand von oben, weiter nach unten */
    left: 0%;
    right: 0%;
    bottom: 0%;
    z-index: 999;
    background-color: #ffffff;
    color: #333;
    overflow: hidden;
    /* Verhindert das Scrollen des gesamten Warenkorbs */
    display: flex;
    /* Flexbox aktivieren */
    flex-direction: column;
    justify-content: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 0px;
    transition: top 0.3s ease-in-out;
    /* Smooth slide animation */
  }

  .shoppingCart.open {
    /* top: 12%; */
    bottom: -80vh;
  }

  .headerCart {
    position: relative;
  }

  .chevron {
    display: block;
    position: absolute;
    right: 30px;
    top: 15px;
  }

  .quantityButton { 
    width: 20px; 
     height: 20px; 
     font-size: 16px; 
     font-weight: bold;
     } 
 
}

.headerCart h3 {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 22px;
  font-weight: bold;
  color: #050505;
  text-transform: capitalize;
}

.cartIcon {
  font-size: 28px;
  color: #050505;
}

.itemCount {
  background-color: #050505;
  color: #ffffffdd;
  font-weight: bold;
  border-radius: 50%;
  padding: 5px 10px;
  min-width: 20px;
  text-align: center;
  margin-left: 10px;
}

/* Toggle Styling für Liefermethode */
.deliveryMethodToggle {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  padding-bottom: 20px;
  cursor: pointer;
}

.deliveryMethodToggle .slider {
  display: flex;
  gap: 5px;
  width: 300px;
  background-color: #fffcfce7;
  /* Dezenter Hintergrund für den Slider */
  border-radius: 20px;
  padding: 6px;
  border: 1px solid #ccc;
  /* Dünner Rand */
}

.deliveryMethodToggle .toggleOption {
  flex: 1;
  text-align: center;
  font-size: 17px;
  /* Schriftgröße für den Toggle */
  padding: 12px 0;
  border-radius: 20px;
  color: #525151;
  /* Dunklere Schriftfarbe für bessere Lesbarkeit */
  transition: color 0.3s ease, background-color 0.3s ease;
}

/* Highlight für den aktiven Bereich */
.deliveryMethodToggle .slider.lieferung .toggleOption:first-child {
  background-color: #ffd700;
  color: #060606;
}

.deliveryMethodToggle .slider.abholung .toggleOption:last-child {
  background-color: #ffd700;
  /* Weiches Orange für Abholung */
  color: #060606;
  /* Dunkle Schriftfarbe für Kontrast */
}

.deliveryMethodToggle .toggleOption.active {
  background-color: #ffd700; /* Highlight Farbe */
  color: #060606; /* Kontrastfarbe */
  font-weight: bold;
  
}

.deliveryMethodToggle .toggleOption.active::after {
  content: " ✓"; /* Häkchen hinzufügen */
  right: 10px; /* Abstand von der rechten Seite */
  font-size: 1em;
  color: #060606;
}
/* Hover-Effekt für Optionen */
.deliveryMethodToggle .toggleOption:hover {
  background-color: #d3d3d3;
  /* Hellerer Hintergrund beim Hover */
  color: #333;
  /* Dunklere Schriftfarbe beim Hover */
}


/* Artikel Liste */
.shoppingCart ul {
  list-style: none;
  padding: 0px 5px;
  margin: 0;
}

.cartItem {
  padding: 5px 0;
  /* Etwas mehr Padding */
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  align-items: start;
  font-size: 0.85rem;
  /* Schriftgröße für die Artikel */
}

/* Container für Produktinformationen */
.cartItemInfo {
  padding: 4px;
  
  
}

/* Styling für die einzelnen Informationen */
.cartItemInfo h4 {
  margin: 0 0 5px 0;
  /* Abstand unter dem Titel */
  font-size: 0.9rem;
  /* Größere Schriftgröße für den Titel */
  color: #333;
  /* Dunklerer Text für den Titel */
  flex: 1;

}

.cartItemInfo p {
  margin: 2px 0;
  /* Abstand zwischen den Absätzen */
  font-size: 0.8rem;
  /* Kleinere Schriftgröße */
  color: #929191;
  /* Hellerer Text für zusätzliche Infos */
  line-height: 1.4;
  /* Zeilenhöhe für bessere Lesbarkeit */
}

.conpricedel{
  display: flex;
  gap: 10px;
  
}

/* Container für die Buttons und die Anzahl */
.cartItemControls {
  display: flex;
  align-items: center;
 justify-content: space-between;
}

/* Stil für die Buttons */
.quantityButton {

  background-color: #f0f0f0;
  /* Heller Hintergrund */
  border: 1px solid #ccc;
  /* Grauer Rand */
  border-radius: 4px;
  /* Abgerundete Ecken */
  width: 30px;
  /* Feste Breite */
  height: 30px;
  /* Feste Höhe */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  /* Zeigt an, dass es ein interaktives Element ist */
  transition: background-color 0.3s;
  font-size: 20px;
}

.quantityButton:hover {
  background-color: #e0e0e0;
  /* Etwas dunklerer Hintergrund beim Hover */
}

/* Stil für die Anzahl */
.quantity {
  font-size: 16px;
  /* Schriftgröße */
  font-weight: bold;
  /* Fettschrift */
  text-align: center;
  /* Zentrierter Text */
  width: 25px;
  /* Feste Breite */
}

.ItemPrice {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* Stil für den Preis */
.priceDisplay {
  font-size: 14px;
  /* Schriftgröße */
  font-weight: bold;
  /* Fettschrift */
  min-width: max-content;
}

/* Stil für den Entfernen-Button */
.removeItem {
  background-color: transparent;
  /* Kein Hintergrund */
  border: none;
  /* Kein Rand */
  font-size: 24px;
  /* Größere Schrift für das X */
  color: #ff6666;
  /* Rote Farbe für das X */
  cursor: pointer;
  /* Zeigt an, dass es ein interaktives Element ist */
  transition: transform 0.3s ease, color 0.3s ease;
  /* Sanfte Übergänge */
  display: flex;
  /* Für zentrierte Positionierung */
  align-items: center;
  /* Zentriert den Inhalt */
  justify-content: center;
  /* Zentriert den Inhalt */
}

/* Animation für das X */
.removeItem:before {
  content: '×';
  /* Unicode für das X */
  font-size: 32px;
  /* Etwas größere Schrift */
  color: #ff6666;
  /* Die gleiche Farbe wie der Button */
  transition: transform 0.3s ease, color 0.3s ease;
  /* Sanfte Übergänge */
}



/* Container für Gesamtsumme und Mindestbestellwert */
.totalContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  /* Rechts ausrichten */
  gap: 8px;
  /* Weniger Abstand zwischen den Elementen */
  padding: 10px 5px;
  margin-top: 13px;
  /* Abstand oben */
}

/* Gesamtsumme */
.totalPriceContainer {
  display: flex;
  justify-content: space-between;
  /* Zwischen Gesamtsumme und Betrag */
  width: 100%;
  border-top: 1px solid #d3d3d3;
  /* Hellgraue Linie */
  padding: 10px 0;
  border-bottom: 1px solid #d3d3d3;
  /* Hellgraue Linie */
}

.totalPriceContainerDiscount{
  display: flex;
  justify-content: space-between;
  /* Zwischen Gesamtsumme und Betrag */
  width: 100%;
  /* Hellgraue Linie */
  padding: 10px 0;
  /* Hellgraue Linie */
}

.totalPriceLabel, .totalPriceLabelDiscount {
  font-size: 1rem;
  /* Schriftgröße */
  color: #333;
  /* Lässige Schriftfarbe */
  margin: 0;
  /* Kein zusätzlicher Abstand */
}

.totalPriceAmount, .totalPriceAmountDiscount {
  font-size: 0.9rem;
  /* Schriftgröße */
  font-weight: bold;
  color: #333;
  margin: 0;
  /* Kein zusätzlicher Abstand */
  text-align: right;
  /* Rechtsbündig */

}

/* Mindestbestellwert */
.minOrderValueContainer {
  display: flex;
  justify-content: space-between;
  /* Zwischen Mindestbestellwert und Betrag */
  width: 100%;
}

.minOrderValueLabel {
  font-size: 0.85rem;
  /* Etwas kleinere Schriftgröße */
  color: #666;
  margin: 0;
  /* Kein zusätzlicher Abstand */
}

.minOrderValueAmount {
  font-size: 0.85rem;
  /* Etwas kleinere Schriftgröße */
  font-weight: bold;
  color: #333;
  margin: 0;
  /* Kein zusätzlicher Abstand */
  text-align: right;
  /* Rechtsbündig */
}


/* Bestell-Button */
.placeOrderButton {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  /* Button Schriftgröße bleibt gleich */
  font-weight: bold;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 10px;
  /* Abstand nach oben */
}

.placeOrderButton.disabled {
  background-color: #ff6666;
  color: white;
}

.placeOrderButton.enabled {
  background-color: #4caf50;
  color: white;
}

.placeOrderButton.enabled:hover {
  background-color: #45a049;
}

/* Stil für den Entfernen-Button */
.removeItem {
  background-color: transparent;
  /* Kein Hintergrund */
  border: none;
  /* Kein Rand */
  font-size: 24px;
  /* Größere Schrift für das X */
  color: #ff6666;
  /* Rote Farbe für das X */
  cursor: pointer;
  /* Zeigt an, dass es ein interaktives Element ist */
  transition: transform 0.3s ease, color 0.3s ease;
  /* Sanfte Übergänge */
  display: flex;
  /* Für zentrierte Positionierung */
  align-items: center;
  /* Zentriert den Inhalt */
  justify-content: center;
  /* Zentriert den Inhalt */
}

/* Animation für das X */
.removeItem:before {
  content: '×';
  /* Unicode für das X */
  font-size: 32px;
  /* Etwas größere Schrift */
  color: #ff6666;
  /* Die gleiche Farbe wie der Button */
  transition: transform 0.3s ease, color 0.3s ease;
  /* Sanfte Übergänge */
}


.messageBox {
  margin-top: 20px;
  padding: 0 5px;

}

.messageBox label {
  font-size: 0.85rem;
  /* Etwas kleinere Schriftgröße */
  color: #666;
  margin: 0;
  /* Kein zusätzlicher Abstand */
}

.messageBox textarea {
  width: 100%;
  height: 70px;
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #ddd;
  font-size: 14px;
  box-sizing: border-box;
  resize: none;
  /* Verhindert das Verschieben der Textarea */
  outline: none;
  /* Entfernt den Fokusrahmen */
  transition: border-color 0.3s ease;
  margin-bottom: 10px;
}

.messageBox textarea:focus {
  border-color: #ffd700;
  /* Betont das Textfeld beim Fokussieren */
}

.messageBox textarea::placeholder {
  color: #aaa;
}