/* Modal Overlay */
.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    animation: fadeIn 0.5s ease forwards;
    /* Fading Animation */
}

/* Modal Content */
.modalContent {
    background: #faf9f9;
    /* Roter Farbton wie ein Warnschild */
    color: #202020;
    /* Dunkelrote Schriftfarbe */
    padding: 15px;
    border-radius: 15px;
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
    text-align: center;
    max-width: 400px;
    width: 80%;
    border: 3px solid #cc4141;
    /* Passender Rand */
    font-size: 1rem;
    transform: scale(0);
    /* Start-Skala für das Aufploppen */
    animation: popUp 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55) forwards;
    /* Pop-Up Animation */

}

/* Button für "Ich möchte nur stöbern" */
.browseButton {
    margin-top: 15px;
    padding: 10px 15px;
    background-color: #ffd000;
    /* Blauer Farbton */
    color: rgb(19, 19, 19);
    border: none;
    border-radius: 30px;
    font-size: 0.9rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.browseButtonContainer {
    display: flex;
    justify-content: space-between; /* Verteilt die Buttons gleichmäßig, mit Abstand zwischen den Elementen */
    text-align: center;
  }


@media screen and (max-width: 1000px) {
    .browseButtonContainer {
        display: flex;
        justify-content: space-between; /* Verteilt die Buttons gleichmäßig, mit Abstand zwischen den Elementen */
        text-align: center;
        gap: 5px;
      }
}
/* Fading Animation */
@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

/* Pop-Up Animation */
@keyframes popUp {
    0% {
        transform: scale(0);
    }

    80% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(1);
    }
}