.banner-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #FFD700; /* Helles Orange */
    color: #000000; /* Weißer Text */
    padding: 5px;
    z-index: 1000;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 70px;
  }
  
  .banner-content {
    flex: 1;
    text-align: left;
    padding-left: 20px;
    text-align: center;
  }
  
  .banner-content h2 {
    font-size: 18px;
    margin: 0;
  }
  
  .banner-content p {
    font-size: 14px;
    margin: 5px 0 0;
  }
  
  .highlight {
    font-weight: bold;
    color: #e74c3c; /* Rot */
  }
  
  .close-banner-button {
    background: none;
    border: none;
    color: #000000;
    font-size: 18px;
    cursor: pointer;
    padding: 10px;
  }
  
  .close-banner-button:hover {
    color: #e74c3c; /* Rotes Highlight */
  }
  
  @media (max-width: 768px) {
    .banner-content h2 {
      font-size: 16px;
    }
  
    .banner-content p {
      font-size: 12px;
    }
  }
  