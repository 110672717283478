/* Container für die Checkout-Bestätigungsseite */
.container {
  max-width: 600px;
  margin: 0 auto;
  padding: 120px;
  text-align: center;
  color: #555;
}


@media screen and (max-width: 768px) {

  .container {
    padding: 50px;
    margin-top: 80px;
  }

}


/* Nachrichtentext */
.message {
  font-size: 1rem;
}

.container h2 {
  font-size: 1.4rem;
  margin-top: 40px;
}

/* Liste der Bestelldetails */
.orderDetailsList {
  list-style-type: none;
  padding: 0;
  margin: 15px 0;
  text-align: left;
}

.orderDetailsList li {
  padding: 10px;
  font-size: 0.9rem;
  color: #333;
}

.productItem {
  margin-bottom: 5px;
}

.productRow {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ddd;
  align-items: center;
  margin-bottom: 10px;
}

.productName {
  font-weight: bold;
  font-size: 1rem;
}

.productPrice {
  font-size: 0.9rem;
  text-align: right;
}

.subDetails {
  margin-left: 20px;
  margin-top: 5px;
}

.subList {
  list-style: none;
  padding: 0;
  margin: 5px 0 0 0;
}

.subItem {
  display: flex;
  justify-content: space-between;
  line-height: 0.4rem;
}

.price {
  font-size: 0.9rem;
  text-align: right;
  min-width: 50px;
}

.totalPrice {
  text-align: right;
  margin-top: 20px;
}

/* Button-Styling */
.button {
  margin-top: 50px;
  display: inline-block;
  padding: 12px 24px;
  font-size: 1rem;
  background-color: #ffcc00;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.button:hover {
  background-color: #ffcc00c4;
}