/* OrderPage.css */
.welcome-banner {
  background-image: url('../images/SEO\ OPTIMIZED.jpg');
  background-size: cover; /* Bild passt sich der Größe an */
  background-position: center; 
  height: 800px; /* Höhe des Containers */
  display: grid;
  place-items: center;
}


@media screen and (max-width: 600px) {
  .welcome-banner {
    height: 500px; /* Höhe des Containers */
    margin-top: 60px;
  }
  
}

/* Willkommenstitel (Farben angepasst) */
.welcome-content h1 {
  color: #f9c74f; /* Helles Gelb */
  font-size: 1.5rem; /* Größere Schriftgröße für Hervorhebung */
}

.welcome-content h2 {
  color: #e5cf61; /* Kräftiges Gelb */
  font-size: 1.3rem; /* Schriftgröße */
}

.welcome-content h3 {
  color: #c79f02; /* Dunkleres Gelb */
  font-size: 1.1rem; /* Schriftgröße */
}

.welcome-content h1,
.welcome-content h2,
.welcome-content h3 {
  line-height: 0.9; /* Verringert den Abstand zwischen den Zeilen */
  text-align: center;
}

.welcome-content {
  padding: 60px 25px; /* Innenabstand für besseren Look */

}

/* Allgemeines Layout der Seite */
.order-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
  padding: 25px; /* Erhöhter Padding für mehr Luft */
  background-color: #ffffffe9; /* Heller Hintergrund für sanfteren Look */
  margin-top: 0px;
  background-image: url('../images/sample 3.png');
  background-size: 600px 600px;



}


