.rechte-container {
    padding: 25px;
    margin-left: 40px;
  }
  
  .rechte-title {
    font-size: 2rem;
    margin-bottom: 20px;
    margin-top: 55px;
  }
  
  .rechte-content {
    line-height: 1.6;
  }
  
  .rechte-section {
    margin-bottom: 30px;
  }
  
  .rechte-heading {
    font-size: 0.9rem;
    line-height: 0.7;
  }
  
  .rechte-text{
    font-size: 0.8rem;
    margin-bottom: 5px;
    line-height: 0.5;
  }
  .rechte-text-contact{
    font-size: 0.8rem;
    margin-bottom: 5px;
    line-height: 0.4;
  }
  .rechte-subheading {
    font-size: 0.85rem;
    margin-bottom: 8px;
  }
  
  .rechte-link {
    color: #007bff;
    text-decoration: none;
  }
  
  .rechte-link:hover {
    text-decoration: underline;
  }
  

/* Für noch größere Bildschirme */
@media (max-width: 480px) {
    .rechte-text {
        line-height: 1.05; /* Noch komfortabler für große Displays */
    }
    .rechte-content {
        line-height: 1.2;
      }
      .rechte-heading {
        font-size: 0.95rem;
        line-height: 0.85;
      }
      .rechte-container {
        padding: 20px;
        margin-left: 5px;
        margin-right: 5px;
      }
}
@media (max-width: 768px) {
  .rechte-text {
    line-height: 1.0; /* Noch komfortabler für große Displays */
}
.rechte-content {
    line-height: 1.1;
  }
  .rechte-heading {
    font-size: 0.95rem;
    line-height: 0.85;
  }
  .rechte-container {
    padding: 20px;
    margin-left: 5px;
    margin-right: 5px;
  }
}