.dashboard {
  padding: 20px;
  padding-top: 40px;
  /* Platz für den fixierten Header schaffen */
  background-color: #ffffff;
  /* Leichtes Grau für den Hintergrund */
  min-height: 100vh;
}

.kanbanContainer {
  display: flex;
  gap: 15px;
  justify-content: space-between;
  margin-left: 240px;
  overflow-x: scroll;
  min-height: 800px;
}

@media (max-width: 768px) {
  .kanbanContainer {
    margin-left: 0px;
  }
}

.kanbanColumn {
  flex: 1;
  min-width: 250px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.kanbanPending {
  background-color: #ffe0b2;
  /* Helles Beige für ausstehende Bestellungen */
  border-left: 5px solid #fb8c00;
  /* Orangefarbener Rand */
}

.kanbanConfirmed {
  background-color: #e3f2fd;
  /* Hellblauer Hintergrund */
  border-left: 5px solid #42a5f5;
  /* Mittelblauer Rand */
}

.kanbanCompleted {
  background-color: #c8e6c9;
  /* Hellgrüner Hintergrund */
  border-left: 5px solid #66bb6a;
  /* Dunkelgrüner Rand */
}

/* Kanban-Säule für stornierte Bestellungen */
.kanbanCanceled {
  background-color: #f2dede;
  /* Hellroter Hintergrund für stornierte Bestellungen */
  border-left: 5px solid #d9534f;
  /* Roter Rand */
}

.orderCard {
  background: #ffffff;
  padding: 15px;
  margin: 10px 0;
  border-radius: 10px;
  /* Leicht abgerundete Ecken */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  /* Leichter Schatten */
  cursor: pointer;
  transition: box-shadow 0.3s, transform 0.3s;
}

.orderCard:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
}

.orderCard p {
  margin: 5px 0;
  color: #333;
  /* Dunklere Schriftfarbe */
  font-size: 14px;
}

.popupouter{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 30px 30px;
  background-color: rgba(0, 0, 0, 0.7);
  display: grid;
  place-items: center;
  z-index: 1000;
  overflow-y: auto;
}

.popup {
  background: hwb(0 100% 0%);
  padding: 20px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  /* Leicht abgerundete Ecken */
  width: 80%;
  max-width: 700px;
  max-height: 80vh;
  /* Höhe auf 80% des Viewports begrenzt */
  overflow-y: auto;
  /* Scrollbar bei Überlauf */
}

.popupHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.popupTitle {
  font-size: 24px;
  color: #333333;
  /* Dunkelgraue Schrift */
  font-weight: 600;
}

.popupContent {
  margin-top: 0px;
  color: #555;
}

.popupButton {
  background-color: #007bff;
  /* Blau für Schaltflächen */
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 5px;
  /* Leicht abgerundete Ecken */
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.popupButton:hover {
  background-color: #0056b3;
  /* Dunklere Schaltfläche bei Hover */
}

.closeButton {
  background-color: #000000;
  /* Rot für Schließen-Schaltfläche */
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 5px;
  /* Leicht abgerundete Ecken */
  cursor: pointer;
  font-size: 16px;
}

/* Bestelldetails */
.orderDetails {
  padding: 0px;
  border-top: 1px solid #eeeeee;
  /* Heller Grauton */
}

.orderItem {
  padding: 0px 0 15px;
  border-bottom: 1px solid #dddddd;
  
  /* Heller Grauton */
}

.orderItem h4 {
  font-size: 18px;
  font-weight: 600;
  color: #333333;
  margin-bottom: 5px;
  /* Dunkelgraue Schrift */
}

.orderItem p {
  font-size: 14px;
  color: #555555;
  /* Mittelgraue Schrift */
  margin: 5px 0;
}

.orderSummary {
  border-top: 1px solid #eeeeee;
  /* Heller Grauton */
  padding-top: 10px;
  margin-top: 20px;
  font-size: 16px;
  color: #777777;
}

.extrasList,
.dipsList {
  margin: 1px 20px;
  font-size: 14px;
  color: #555555;
  /* Mittelgraue Schrift */
}

/* Scrollbar Styling (optional) */
.popup::-webkit-scrollbar {
  width: 8px;
}

.popup::-webkit-scrollbar-thumb {
  background-color: #b0b0b0;
  /* Mittelgrauer Scrollbalken */
  border-radius: 4px;
}

.popup::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.confirmButtons {
  display: flex;
  gap: 10px;
  margin-top: 15px;
 
}

.timeButton,
.confirmButton,
.closeButton {
  padding: 10px 20px;
  border-radius: 5px;
  /* Leicht abgerundete Ecken */
  border: none;
  cursor: pointer;
  font-size: 16px;
}

.timeButton{
  background-color: #dbe4dd;
  /* Grüner Bestätigungsbutton */
  color: #fff;
}

.selectedTime {
  background-color: #5fde35e7; /* Blaue Hintergrundfarbe für den ausgewählten Button */
  color: white; /* Weiße Schriftfarbe für besseren Kontrast */
  border-color: #343535; /* Dunklere blaue Rahmenfarbe */
  font-weight: bold; /* Macht den Text fett */
}

.confirmButton {
  background-color: #28a745;
  /* Grüner Bestätigungsbutton */
  color: #fff;
}

.closeButton {
  background-color: #dc3545;
  /* Roter Schließen-Button */
  color: #fff;
}

.cancelprintbuttons {
  display: flex; /* Flexbox für einfache Ausrichtung */
  gap: 75px; /* Abstand zwischen den Buttons */
  justify-content: flex-start; /* Buttons linksbündig ausrichten */
  align-items: center; /* Vertikale Zentrierung */
}





.tableContainer {
 
  margin-left: 245px;
}

.orderTable {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  padding: 12px;
  text-align: left;
  border: 1px solid #ddd;
}

th {
  background-color: #f4f4f4;
  font-weight: bold;
}


/* Flexbox-Container für bessere Struktur */
.buttonContainer {
  display: flex;
  align-items: center;
  gap: 20px; /* Abstand zwischen den Buttons */
}

/* Neuer, größerer Pfeil-Button für Statusänderung */
.arrowButton {
  background-color: #2ecc71; /* Grün */
  color: white;
  padding: 10px 14px;
  font-size: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.arrowButton:hover {
  background-color: #27ae60;
}

/* Drucken in dunklem Blau/Violett für Kontrast */
.printButton {
  background-color: #535257; /* Lila */
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 12px;
}

.printButton:hover {
  background-color: #535257a4;
}

/* Stornieren bleibt rot */
.cancelButton {
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 12px;
  align-items: end;
}

.cancelButton:hover {
  background-color: #d32f2f;
}

/* Details-Button */
.detailsButton {
  background-color: #899ba7; /* Blau */
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 12px;
}

.detailsButton:hover {
  background-color: #6a889cc0;
}

/* Stornierungsbestätigung */
.confirmCancelContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}


.ausstehend td {
  background-color: rgba(255, 166, 0, 0.963) !important;
}

.bestätigt td {
  background-color: rgb(235, 240, 241) !important;
}

.abgeschlossen td {
  background-color: lightgreen !important;
}

.storniert td {
  background-color: rgb(242, 144, 144) !important;
}

.soundButton {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.1rem; /* Größere Schrift */
  padding: 11px 18px; /* Mehr Padding für größere Klickfläche */
  border: none;
  border-radius: 8px; /* Abgerundete Ecken */
  background-color: #c83131; /* Blau */
  color: white;
  cursor: pointer;
  transition: background 0.3s, transform 0.1s;
}

/* Sound aus (Rot) */
.soundOff {
  background-color: #dc3545; /* Rot */
}

.soundOff:hover {
  background-color: #c82333; /* Dunkleres Rot */
}

/* Sound an (Grün) */
.soundOn {
  background-color: #28a745; /* Grün */
}

.soundOn:hover {
  background-color: #218838; /* Dunkleres Grün */
}

.soundButton:active {
  transform: scale(0.95);
}
