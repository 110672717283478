body {
  height: 100%;
  margin: 0; 
  color: rgb(40, 39, 39); 
  font-family: 'Roboto', sans-serif;
  width: 100%;
}

.scrollbar-hidden {
  overflow: hidden; 
}




