footer {
    background-color: #000;
    color: #fff;
    padding: 40px 25px;
}

@media screen and (max-width: 1000px) {
    footer {
        padding-bottom: 100px;
    }
}

.footer-container {
    max-width: 1200px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.footer-container div {
    flex: 1;
}

.footer-container h4 {
    color: #FFD700;
    margin-bottom: 10px;
}

.footer-container p, .footer-container a {
    color: #fff;
    font-size: 14px;
    text-decoration: none;
}

.footer-container a:hover {
    color: #FFD700;
    text-decoration: none;
}

.footer-cookie-banner:hover{
    color: #FFD700;
    cursor: pointer;
}