/* Modal Overlay */
.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  /* Modal Content */
  .modalContent {
    background: #fff;
    padding: 20px;
    width: 300px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    position: relative;
    display: flex;
    flex-direction: column;
  }
  
 /* Schließen-Button im Popup */
.closeButton {
    position: absolute;
    top: 25px;
    right: 25px;
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
  }
  
  /* List Styling */
  ul {
    list-style-type: none;
    padding: 0;
    
  }
  
  li {
    padding: 3px 0;
    font-size: 14px;
    text-align: left;
    color: #5c5c5c; 
    
  }

  .productInfoTitles h4 {
    color: #3f3f3f; /* Hellere Schriftfarbe */
    font-size: 1.0rem;
    margin-bottom: 5px;
    font-style: italic;
  }

  .productInfoTitles h3 {
    color: #3f3f3f; /* Hellere Schriftfarbe */
    font-size: 1.1rem;
    margin-bottom: 5px;
    font-weight: bold;
  }
  