/* adminSidebar.module.css */
.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 250px;
  background-color: #424448;
  color: white;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* Platz zwischen den Elementen */
  z-index: 1000;
  transform: translateX(0);
  /* Always open on larger screens */
  transition: transform 0.3s ease-in-out;
}

.sidebarinner {
  position: relative;
  margin-top: 18px;
}

.toggleButton {
  display: none;
  position: absolute;
  top: 0px;
  right: -60px;
  background-color: #333;
  color: white;
  border: none;
  padding: 10px 15px;
  font-size: 18px;
  cursor: pointer;
  z-index: 101;
  border-radius: 10px;
}

.toggleButton:hover {
  background-color: #555;
}

@media (max-width: 768px) {

  .toggleButton {
    display: block;
  }

  .sidebar {
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
  }

  .sidebar.open {
    transform: translateX(0);
  }
}

@media (min-width: 768px) {
  .sidebar {
    transform: translateX(0);
  }
}

.sidebarContent {
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 0 10px;
  flex: 1;
  /* Nimmt den verbleibenden Platz ein */
}

.iconContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

.icon {
  font-size: 2rem;
  color: white;
}

.iconText {
  font-size: 1.8rem;
  font-weight: 700;
  color: white;
  margin-left: 10px;
}

.navLinks {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.navLinks li {
  margin-bottom: 20px;
  width: 100%;
  text-transform: capitalize;
  display: flex;
}


.navButton {
  background-color: #56585c;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-align: center;
  font-size: 1rem;
  width: 100%;
  text-decoration: none;
  /* Entfernt Unterstreichung */
}

.navButton:hover {
  background-color: #484a4e;
}


.toggleContainer {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  border-radius: 50%;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
}

input:checked+.slider {
  background-color: #28a745;
}

input:checked+.slider:before {
  transform: translateX(26px);
}

.slider.green {
  background-color: #28a745;
}

.slider.red {
  background-color: #dc3545;
}

.logoutContainer {
  margin-bottom: auto;
  /* Positioniert den Logout-Button am Ende */
  padding: 10px 20px;
}

.logoutButton {
  background-color: #d9534f;
  width: 100%;
  text-align: center;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  color: rgb(255, 255, 255);
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-transform: capitalize;
}

.logoutButton:hover {
  background-color: #c9302c;
}